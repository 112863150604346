import './UnpyUp.scss';

import { Cancel, Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { toUpper } from 'lodash';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { PreviewCardProfil } from 'primary/Components/Profils/PreviewCardProfil';
import { UnpyLogoUp } from 'primary/Components/UnpyLogo/UnpyLogoUp';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchAllProducts } from 'primary/hooks/useFetchAllProducts';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { UnpyUpOffers } from 'primary/Up/UnpyUpOffers';
import React, { useRef } from 'react';
import { ReactCompareSlider } from 'react-compare-slider';

import { useTranslate } from '../../hooks/useTranslate';

export const UnpyUpPage = () => {
  const t = useTranslate();
  const { allProfilRepository } = useContextDependency();
  const products = useFetchAllProducts();
  const [compareProfil] = useRetrieveFromDomain(
    () => allProfilRepository.compareProfil(),
    undefined,
  );
  const ref = useRef<HTMLDivElement | null>(null);
  const goToRef = () => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  if (!products) return <LoadingScreen loading={true} />;
  return (
    <div className={'upPage'}>
      <Grid container className={'-containerTitle'}>
        <h1>
          {t('up.titlePartChange')}
          <span>{t('up.titlePartAppearence')}</span>
          {toUpper(t('up.titlePartWith'))}
          <span>{toUpper(t('up.titlePartUp'))} !</span>
        </h1>
      </Grid>
      <div className={'-containerUpContent'}>
        <div className={'-containerCompare'}>
          <div className={'-itemCompareWrapper'}>
            {compareProfil && (
              <ReactCompareSlider
                itemOne={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      margin: 'auto',
                      flexBasis: 'auto',
                      zIndex: 0,
                    }}
                  >
                    <PreviewCardProfil
                      noAction
                      className={'-itemCompare'}
                      key={compareProfil.compareDefault.id}
                      profil={compareProfil.compareDefault}
                    />
                  </div>
                }
                itemTwo={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      zIndex: 0,
                      flexBasis: 'auto',
                      margin: 'auto',
                    }}
                  >
                    <PreviewCardProfil
                      noAction
                      className={'-itemCompare'}
                      key={compareProfil.compareDefault.id}
                      profil={compareProfil.compareUp}
                    />
                  </div>
                }
              />
            )}
          </div>
        </div>
        <div className={'-containerUpButtonsPart'}>
          <UnpyUpOffers embedded products={products} onClickSeeMore={goToRef} />
        </div>
      </div>
      <Grid container className={'-containerTitle -end'} ref={ref}>
        <h1>
          Profite de tout les <span> avantages </span> de l'abonnement Up et Up
          <span> premium </span> <br />
        </h1>
        <div className={'-datatableAdvantages'}>
          <div className={'-header'}>Fonctionnalité</div>
          <div className={'-header'}>Free</div>
          <div className={'-header'}>
            <UnpyLogoUp withoutLink size={'medium'} />
          </div>
          <div className={'-header -column'}>
            <UnpyLogoUp withoutLink size={'medium'} /> premium
          </div>
          <div className={classNames('-item -label')}>
            Limite mensuel de participation aux événement
          </div>
          <div className={classNames('-item', '-litleQuantity')}>20 / mois</div>
          <div className={classNames('-item', '-mediumQuantity')}>40 / mois</div>
          <div className={classNames('-item', '-unlimitedQuantity')}>Illimité</div>
          <div className={classNames('-item -label')}>
            Limite mensuel de création d'événement
          </div>
          <div className={classNames('-item', '-litleQuantity')}>3 / mois</div>
          <div className={classNames('-item', '-mediumQuantity')}>10 / mois</div>
          <div className={classNames('-item', '-unlimitedQuantity')}>Illimité</div>
          <RowItemDatatableUp
            label={'Cosmétique'}
            free={false}
            up={true}
            premium={true}
          />
          <RowItemDatatableUp
            label={'Messagerie unpy'}
            free={false}
            up={true}
            premium={true}
          />
          <RowItemDatatableUp
            label={'Sans Publicités'}
            free={false}
            up={true}
            premium={true}
          />
          <RowItemDatatableUp
            label={'Support premium'}
            free={false}
            up={false}
            premium={true}
          />
          <RowItemDatatableUp
            label={'Certification'}
            free={false}
            up={false}
            premium={true}
          />
          <RowItemDatatableUp
            label={'PP Et Banières animés'}
            free={false}
            up={false}
            premium={true}
          />
        </div>
      </Grid>
    </div>
  );
};

type RowItemDatatableUpProps = {
  label: string;
  free: boolean;
  up: boolean;
  premium: boolean;
};

export const RowItemDatatableUp = ({
  label,
  free,
  up,
  premium,
}: RowItemDatatableUpProps) => {
  return (
    <>
      <div className={classNames('-item -label')}>{label}</div>
      <div className={classNames('-item', { '-valid': free }, { '-notValid': !free })}>
        {free ? <Check /> : <Cancel />}
      </div>
      <div className={classNames('-item', { '-valid': up }, { '-notValid': !up })}>
        {up ? <Check /> : <Cancel />}
      </div>
      <div
        className={classNames('-item', { '-valid': premium }, { '-notValid': !premium })}
      >
        {premium ? <Check /> : <Cancel />}
      </div>
    </>
  );
};
